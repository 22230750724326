"use client"
import Stack from "@mui/material/Stack"
import TextField, { TextFieldProps } from "@mui/material/TextField"
import { isValidNumberString } from "@tc/ui-shared/utils"
import { useId, useState } from "react"
import {
  Controller,
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form"

export type FormTextFieldProps<T extends FieldValues> = TextFieldProps &
  UseControllerProps<T>

export const FormTextField = <T extends FieldValues>(
  props: FormTextFieldProps<T>,
) => {
  const { id, name, control, required, rules, type, ...textFieldProps } = props
  const autoId = useId()
  const _id = id || autoId
  const fieldState = control?.getFieldState(name)
  const { field } = useController({ name, control, rules })
  const [inputValue, setInputValue] = useState(field.value || "")
  return (
    <Stack>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          const { ref, ...fieldProps } = field
          return (
            <TextField
              {...fieldProps}
              onChange={(e) => {
                if (type === "number") {
                  if (isValidNumberString(e.target.value, required)) {
                    setInputValue(e.target.value)
                    if (!e.target.value) {
                      field.onChange("")
                      return
                    }
                    field.onChange(+e.target.value)
                  }
                  return
                }
                field.onChange(e.target.value)
                setInputValue("")
              }}
              id={_id}
              error={fieldState?.invalid}
              helperText={fieldState?.error?.message as string | undefined}
              inputProps={{
                "data-testid": "test-formTextField-input",
              }}
              {...textFieldProps}
              inputRef={ref}
              value={inputValue || field.value}
            />
          )
        }}
      ></Controller>
    </Stack>
  )
}
