"use client"
import { Close } from "@mui/icons-material"
import { IconButton, InputAdornment } from "@mui/material"
import MenuItem from "@mui/material/MenuItem"
import Stack from "@mui/material/Stack"
import TextField, { TextFieldProps } from "@mui/material/TextField"
import { MenuItemKeyValuePair } from "@tc/ui-shared/utils"
import { useId } from "react"
import { Controller, FieldValues, UseControllerProps } from "react-hook-form"

export type FormSelectProps<T extends FieldValues> = TextFieldProps &
  UseControllerProps<T> & {
    options: readonly MenuItemKeyValuePair[]
    clearable?: boolean
  }

export const FormSelect = <T extends FieldValues>(
  props: FormSelectProps<T>,
) => {
  const {
    name,
    control,
    options,
    id,
    clearable,
    rules,
    onChange,
    ...textFieldProps
  } = props
  const autoId = useId()
  const _id = id || autoId
  const fieldState = control?.getFieldState(name)

  return (
    <Stack>
      <Controller
        name={name}
        control={control}
        rules={rules}
        render={({ field }) => {
          return (
            <TextField
              {...field}
              select
              onChange={(event) => {
                if (onChange) onChange(event)
                field.onChange(event.target.value)
              }}
              id={_id}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {clearable && field.value && (
                      <IconButton
                        size="small"
                        onClick={() => field.onChange(null)}
                      >
                        <Close fontSize="small" />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
              sx={{
                "& .MuiInputBase-root": {
                  pr: 4,
                },
              }}
              error={fieldState?.invalid}
              helperText={fieldState?.error?.message as string | undefined}
              defaultValue=""
              value={field.value ?? ""}
              inputProps={{
                "data-testid": "test-formSelect-input",
              }}
              {...textFieldProps}
              data-testid="test-formSelect"
            >
              {options?.map((option: MenuItemKeyValuePair) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
          )
        }}
      ></Controller>
    </Stack>
  )
}
